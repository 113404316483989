@import "../Global.scss";

.tickets-download {


    .MuiListItem-root {
        background-color: $itemBackground;
        border-radius: 10px;
    }


    .MuiDivider-root {
        border: none;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
