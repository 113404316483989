@import "../Global.scss";

.page {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > header {
        z-index: 100;

        img{
            max-width: 130px;
            height: 56px;
            margin-left: -16px;
            margin-right: 16px;
        }
    }

    > *:nth-child(2) {
        padding-top: 16px;
        padding-bottom: 16px;
        background-color: white;
        flex-grow: 1;
    }
}

.main-drawer {

    .MuiPaper-root {
        background: $backColor-dark;
        min-width: 70vw;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        .MuiTypography-body1 {
            color: $white;
        }

        .MuiListSubheader-root {
            background-color: unset;
            color: $white;
        }

        .main {
            flex-grow: 1;
        }

        > footer {
            background: #D9D9D9;
            color: $text-color-dark-2;
            padding: 12px 16px 12px 32px;
            display: flex;
            flex-direction: row;

            .content {
                flex-grow: 1;
            }

            svg {
                width: 24px;
            }

            .MuiTypography-body1 {
                color: $text-color-dark-2;
            }

            .MuiTypography-body2 {
                color: $text-color-dark-2;
            }
        }

        > header {

            img {
                margin-top: 16px;
                max-width: 250px;
            }

            .user {
                text-align: center;
                margin-top: 32px;
                margin-bottom: 32px;
            }
        }

        .version {
            padding: 16px;
            color: $text-color-medium;
        }


        .MuiListItemIcon-root {
            color: $itemBackground;

            img {
                width: 26px;
                height: 26px;
                border-radius: 100%;
                object-fit: cover;
            }
        }
    }
}


.MuiToolbar-root {
    .MuiTypography-h6 {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}