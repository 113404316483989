$itemBackground: #EEEEEE;

$border-color: $itemBackground;

$text-color-dark-1: #333333;
$text-color-dark-2: #4D4D4D;
$text-color-medium: #999999;

$backColor-dark: #4D4D4D;
$backColor-light: #F7F7F7;

$progress-color: #cccccc;
$seat-color: #1976D3;

$white: #FFF;
$black: #000;

$yellow-1: #E8BA16;
$yellow-2: #fdd835;
$red-1: #e53935;
$green-1: #819E1F;
$green-2 :#16887A;
$blue: #1E88E5;

$font-size-small: 13px;
$font-size-normal: 16px;
$font-size-large: 20px;
$font-size-very-large: 24px;


@mixin spin {
    animation-name: spin;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-origin: center center;
}

