
@import "../Global.scss";

.install-banner {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
    transition-property: height;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;

    > .content {
        background: $white;
        display: flex;
        flex-direction: column;
        position: relative;
        border-start-end-radius: 8px;
        border-start-start-radius: 8px;
        overflow: hidden;
        height: 100%;
    }

    &.semi-open {
        > .content {
            box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
        }
    }


    header {
        display: flex;
        flex-direction: column;
        padding: 8px;
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: solid 1px $border-color;
        flex-shrink: 0;

        .closeButton {
            position: absolute;
            right: 0;
            top: 0;
        }

        .message {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
        }

        .icon {
            width: 32px;
        }

        .skeleton {
            width: 30px;
            height: 6px;
            background-color: #bbb;
            margin-bottom: 8px;
            align-self: center;
        }

        .dont-show {
            align-self: flex-end;

            .MuiFormControlLabel-root {
                span, svg {
                    font-size: 0.9rem;
                }
            }
        }
    }

    section {
        overflow-y: auto;
        flex-grow: 1;

        .step {
            padding: 16px;

            h6 {
                margin-bottom: 8px;
            }
        }

        img {
            max-width: 100%;
            box-sizing: border-box;
            border: solid 2px $border-color;
            padding: 8px;
        }
    }
}
