@import "../Global.scss";

.tickets-map {
    display: flex;
    flex-direction: column;


    .MuiContainer-root {
        padding-top: 8px;

        .map-view {
            height: 50vh;
        }
    }

    .MuiList-root {
        max-height: 20vh;
        overflow-y: auto;
    }

    .seat-list {
        .MuiButtonBase-root {
            padding-right: 0;
            padding-left: 0;
        }

        .MuiListItemText-primary {
            display: flex;
            flex-direction: row;
            gap: 16px;
            text-transform: uppercase;
            align-items: start;
            padding-right: 8px;

            .field {
                span {
                    white-space: nowrap;
                }
            }

            .sector {
                font-weight: bold;
                flex-shrink: 1;
                flex-grow: 1;
    
                align-self: center;
            }

            .field-row p {
                font-weight: bold;
            }

            .field-seat p {
                border-radius: 100%;
                background-color: $seat-color;
                color: $white;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                flex-shrink: 0;
                margin-top: -3px;
            }
        }
    }

    .venue {
        margin-top: 24px;

        .geo-map {
            width: 100%;
        }

        address {
            margin-top: 8px;
            color: $black;

            p {
                white-space: pre;
            }
        }

        .actions {
            display: flex;
            flex-direction: row;
            border-top: solid 1px $border-color;
            border-bottom: solid 1px $border-color;
            margin-top: 16px;

            > * {
                flex-basis: 0;
                flex-grow: 1;
            }
        }
    }
}
