@import "../Global.scss";

.tickets-code {
    display: flex;
    flex-direction: column;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    overflow-y: hidden !important;

    .swiper {
        flex-grow: 1;
    }

    > .MuiContainer-root {
        height: 100%;
        overflow: hidden;
        padding: 0;
    }

    .react-swipeable-view-container {
        height: 100%;
    }

    .MuiContainer-root {
        display: flex;
        flex-direction: column;
        overflow: hidden;


        > header {
            border-bottom: solid 1px $border-color;
            margin-bottom: 16px;
            padding-bottom: 16px;
        }

        .swiper {
            flex-grow: 1;
            flex-basis: 0;
            overflow: hidden;
        }
    }

    .MuiMobileStepper-root {
        padding-top: 0;
    }

    .ticket-details {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
        padding: 16px 24px 0px 24px;
        box-sizing: border-box;



        h4 {
            font-size: $font-size-very-large;
            font-weight: 700;
            text-align: center;
        }

        header {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            padding: 10px 16px 0 16px;
            background-color: $itemBackground;
            display: flex;
            flex-direction: column;
            flex-shrink: 1;
            gap: 8px;
            margin-bottom: -10px;
            overflow: visible;
            z-index: 2;


            h5 {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 8px;
                text-overflow: ellipsis;
                overflow: hidden;
                flex-shrink: 1;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                display: -webkit-box;
            }

            .field-note span, .field-place span {
                display: none !important;
            }

            .row {
                display: flex;
                flex-direction: row;
                gap: 16px;
                overflow: hidden;

                &.inline {

                    .field {
                        flex-direction: row;
                        display: flex;

                        span {
                            display: inline-block;
                            margin-right: 1em;
                            margin-bottom: 0;
                        }
                    }
                }

                > * {
                    span {
                        font-size: $font-size-small;
                        line-height: unset;
                        margin-bottom: 5px;
                        display: block;
                        color: $text-color-dark-1;
                    }

                    p {
                        font-size: $font-size-small;
                        font-weight: bold;
                        line-height: unset;
                        color: $text-color-dark-1
                    }

                    &.filler, &.field-sector {
                        flex-grow: 1;
                        flex-shrink: 1;
                    }


                    &:first-child {
                        text-align: left;
                    }
                }
            }

            .order {
                text-align: center;
                text-transform: uppercase;

                .MuiTypography-body1 {
                    font-size: $font-size-small;
                }
            }

            .ticket-num {
                font-size: 12px;
                text-align: center;
            }
        }

        .separator {
            display: flex;
            align-items: center;
            background-color: $itemBackground;

            .center {
                flex-grow: 1;
                border-bottom: dashed 3px $white;
                margin-left: -10px;
                margin-right: -20px;
            }

            .left {
                height: 40px;
                width: 20px;
                background: radial-gradient(circle at left, $white 0%,$white 50%,$itemBackground 50%,$itemBackground 100%);
            }

            .right {
                height: 40px;
                width: 20px;
                background: radial-gradient(circle at right, $white 0%,$white 50%,$itemBackground 50%,$itemBackground 100%);
            }
        }

        .code-container {
            display: flex;
            flex-grow: 1;
            overflow: hidden;
            padding: 0 16px 10px 16px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            justify-content: center;
            flex-direction: row;
            background-color: $itemBackground;
            margin-top: -10px;



            img {
                border-radius: 10px;
                image-rendering: pixelated;
                object-fit: contain;
                max-width: calc(100vw - 100px);
            }
        }

        svg {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            fill: $text-color-medium;
        }
    }
}
