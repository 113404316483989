

.expand-text {
    display: flex;
    flex-direction: column;

    svg{
        width: 32px;
        height: 32px;
    }

    > .content {
        overflow: hidden;
        flex-grow: 1;
        position: relative;
    }

    &.expanded {
        svg {
            transform: rotate(180deg);
            transform-origin: center center;
        }
    }


    &.collapsed {
        .content {
            &::after {
                position: absolute;
                height: 3em;
                bottom: 0;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                content: ' ';
            }
        }
    }
}
