.page.profile {

    .web-view {
        border: none;
        width: 100%;
        height: 100%;
        padding: 0;
        overflow: hidden;
    }
}
