@import "../Global.scss";

.switch-selector {
    display: flex;
    flex-direction: row;

    > * {
        background-color: $backColor-dark;
        color: $white;
        border-radius: 10px;
        font-size: 11px;
        text-transform: uppercase;
        min-width: 85px;
        text-align: center;
        padding: 6px 0 6px 0;
        cursor: pointer;

        &:first-child {
            z-index: 1;
        }

        &:not(:first-child) {
            margin-left: -15px;
        }
    }
}
