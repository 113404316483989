@import "../Global.scss";

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    text-align: center;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    font-size: 20px;

    svg {
        width: 40vh;
        height: 40vh;
        opacity: 0.4;
    }
}