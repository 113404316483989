@import "../Global.scss";

.blocker {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    transition-duration: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .progress-container {
        transition-duration: 0.3s;

        &.hidden {
            display: none;
        }

        svg {
            @include spin;
            fill: $progress-color;
            width: 72px;
            height: 72px;
        }
    }

    &.visible {
        background-color: rgba(0,0,0,0.7);
        transition-delay: 0.3s;

        .progress-container {
            transition-delay: 0.3s;
        }
    }

    &.hidden {
        opacity: 0;
        pointer-events: none;

        .progress-container {
            opacity: 0;
            transform: scale(0);
        }
    }
}
