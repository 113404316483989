@import "../Global.scss";

.loading-view {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;


    .MuiSvgIcon-root {
        @include spin;
        width: 48px;
        height: 48px;
    }
}
