
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.center {
    text-align: center;
}

.MuiListItemText-root.flex-fill {
    flex: unset;
    flex-grow: 1;
}
