.section {
    margin-bottom: 16px;


    &.collasable {
        header {
            display: flex;
            flex-direction: row;

            :first-child {
                flex-grow: 1;
            }
        }
    }
}
