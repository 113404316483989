
.home {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    display: flex;
    flex-direction: column;

    > .MuiPaper-elevation {
        z-index: 2;
    }

    &.has-over {

        .main.content {
            page {
                display: none;
            }
        }
    }

    > .main.content {
        overflow: hidden;
        flex-grow: 1;

        .page {
            .MuiContainer-root {
                overflow-y: scroll;
            }
        }
    }
}

.over.content {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;

    .page {

        > *:nth-child(2) {
            flex-grow: 1;
            overflow-y: auto;
        }

        &.loading {
            opacity: 0;
        }
    }
}