@import "../Global.scss";

.page.events {
    .image-info {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 16px;

        .info {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        img {
            max-width: 100px;
        }
    }


    .venue-section {
        header {
            display: flex;
            gap: 8px;
            border-radius: 10px;
            background-color: $backColor-light;
            padding: 4px 8px 4px 8px;
            text-transform: lowercase;
        }

        .MuiListItemText-primary {
            color: $text-color-dark-1;
            font-weight: 600;
            font-size: $font-size-normal;
        }

        .MuiListItemText-secondary {
            display: flex;
            flex-direction: row;
            font-size: $font-size-small;
            font-weight: 600;
            color: $text-color-medium;
            margin-top: 8px;


            div {
                flex-basis: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
            }
        }
    }

    .movie-section {
        header {
            display: flex;
            gap: 8px;
            border-radius: 10px;
            background-color: $backColor-light;
            padding: 4px 8px 4px 8px;
            text-transform: lowercase;
        }

        .MuiDivider-root {
            border: none;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .MuiListItem-root {
            background-color: $backColor-light;
            border-radius: 10px;
        }

        .MuiListItemIcon-root {
            margin-top: 0;
        }

        .MuiListItemText-primary {
            display: grid;
            grid-template-areas: 'a b' 'c c';
            row-gap: 8px;
            font-size: $font-size-small;
            font-weight: 600;
            color: $text-color-medium;

            .place {
                grid-area: c;
            }

            div {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
            }
        }

        .field {
            flex-direction: row;
            display: flex;
            align-items: self-start;

            span {
                width: 50px;
                text-align: left;
                font-weight: 600;
                font-size: $font-size-small;
                color: $text-color-medium;
                text-transform: none;
                line-height: 18px;
                flex-shrink: 0;
            }

            p {
                font-weight: 700;
                font-size: $font-size-small;
                color: $text-color-medium;
                line-height: 18px;
                text-align: left;
            }
        }
    }


    .date-section {
        header {
            padding: 0;

            svg {
                padding-right: 16px;
            }
        }

        .MuiListItem-root {
            .MuiButtonBase-root {
                align-items: flex-start;
            }
        }

        .MuiListItemText-root {
            .MuiTypography-body1 {
                font-weight: 600;
                margin-bottom: 4px;
            }

            .MuiTypography-body2 {
                font-weight: 600;
                color: $text-color-medium;
                display: flex;
                flex-direction: column;
                gap: 4px;

                svg {
                }

                div {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                }
            }
        }


        .event-date {
            display: flex;
            gap: 8px;
            margin-right: -40px;

            .week-day {
                border-radius: 10px;
                border: 1px solid $text-color-medium;
                width: 60px;
                text-align: center;
                flex-grow: unset;
                padding: 4px 0 4px 0;
                text-transform: lowercase;
            }


            .day-month {
                border-radius: 10px;
                background-color: $backColor-light;
                flex-grow: 1;
                padding: 4px 8px 4px 8px;
                text-transform: lowercase;
            }
        }
    }


    .MuiListItemIcon-root {
        margin-right: 16px;
        margin-top: 10px;
        color: $backColor-dark;

        svg {
            width: 21px;
        }
    }

    .MuiListItem-root {
        padding-left: 0px;
        padding-right: 0px;
    }

    .MuiAutocomplete-popper {
        z-index: 50;
    }

    .keyword-search {
        margin-bottom: 16px;
    }


    h6 {
        font-weight: 500;
    }
}

svg.yellow {
    fill: $yellow-2;
}

svg.red {
    fill: $red-1;
}

svg.green {
    fill: $green-2;
}

svg.blue{
    fill: $blue;
}