@import "../Global.scss";

.my-tickets {

    .MuiContainer-root {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: hidden !important;
        height: 100%;
        padding: 0;
        padding-bottom: 0 !important;

        > footer {
            background-color: $itemBackground;
            font-size: $font-size-normal;
            color: $black;
            padding: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .MuiTypography-body1 {
                color: $black;
            }

            .text {
                flex-grow: 1;
            }

            h6 {
                font-size: $font-size-small;
            }

            .last-update {
                font-size: $font-size-small;
            }

            button {
                padding: 6px 18px;
                font-size: $font-size-normal;
                line-height: 20px;
                border-radius: 5px;
                white-space: nowrap; 
            }
        }


        > .main {
            flex-grow: 1;
            overflow: hidden;
            overflow-y: auto;
            padding-left: 16px;
            padding-right: 16px;

            .title {
                flex-grow: 1;
            }

            > header {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .keyword-search {
                margin-top: 8px;
                margin-bottom: 16px;
            }
        }
    }


    .MuiList-root {
        margin: 0;
        padding-top: 0;
    }

    .MuiListItem-root {
        background-color: $itemBackground;
        border-radius: 10px;


        .MuiButtonBase-root {
            flex-direction: column;
            align-items: unset;


            > .main {
                display: flex;
                flex-direction: row;
                gap: 8px;

                .category {
                    margin-top: 10px;
                    width: 12px;
                    height: 19px;
                    flex-shrink: 0;
                }


                .MuiTypography-body1 {
                    font-size: $font-size-normal;
                    font-weight: 400;
                    color: $text-color-dark-1;
                    line-height: 21px;
                    margin-bottom: 4px;
                }

                .MuiTypography-body2 {
                    font-size: $font-size-small;
                    font-weight: 600;
                    color: $text-color-dark-1;
                    line-height: 140%;
                }
            }
        }

        footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .body3 {
            font-weight: 400;
            line-height: 140%;
            color: $text-color-dark-1;
            margin-top: 4px;
        }

        .badge {
            border-radius: 10px;
            white-space: nowrap;
            font-weight: 600;
            padding: 4px 8px;

            &.MuiButtonBase-root {
                color: $white;
                text-transform: none;
                font-size: $font-size-small;
            }
        }

        .status {
            font-size: $font-size-small;
            line-height: unset;

            &.to-confirm {
                background-color: $yellow-1;
                color: #F4F4F4;
            }

            &.confirmed {
                background-color: $green-1;
                color: #EEEEEE;
            }
        }
    }

    .MuiDivider-root {
        border: none;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
