@import "../Global.scss";

.event-details {

    &.umbriajazz {
        section .actions .MuiButtonBase-root{
            background-color: #333;
        }
    }


    .MuiContainer-root {
        > header {
            margin-bottom: 16px;
        }
    }

    .event-info {
        white-space: pre-wrap;
    }

    .movie-info {

        .field {
            flex-direction: row;
            display: flex;
            align-items: flex-start;
            margin-bottom: 8px;

            span {
                text-align: left;
                font-weight: 600;
                color: $text-color-medium;
                text-transform: none;
                font-size: $font-size-normal;
                line-height: unset;
                flex-shrink: 0;
                white-space: nowrap;
                margin-right: 16px;
            }

            p {
                font-weight: 700;
                font-size: $font-size-normal;
                color: $text-color-medium;
                line-height: unset;
                text-align: left;
            }
        }
    }



    .buy {

        .MuiButton-root.red {
            background-color: $red-1;
        }

        .MuiButton-root.blue {
            background-color: $blue;
        }


        .MuiTypography-body1 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 16px;
            margin-bottom: 16px;
            font-weight: 700;
            color: $text-color-medium;

            div {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 8px;
            }

            svg {
                width: 26px;
                height: 26px;
            }
        }

        .row {
            text-align: center;
        }
    }

    .tickets {
        .MuiListItem-root {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            align-items: stretch;
            gap: 16px;
            padding-left: 0;
            padding-right: 0;


            .body {
                flex-grow: 1;
            }
        }

        .price .MuiTypography-root {
            font-size: $font-size-large;
            font-weight: 700;
            color: $text-color-dark-2;
            line-height: unset;
            white-space: nowrap;
        }


        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;

            .MuiListItemIcon-root {
                min-width: unset;
            }

            > * {
                flex: unset;
            }
        }

        .seats {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            .progress-bar {
                flex-grow: 1;
                background-color: $itemBackground;

                > * {
                    height: 4px;
                }
            }
        }


        .reduced {
            .body {
                margin-left: 16px;
            }
        }
    }

    .date-time {
        margin-bottom: 16px;
    }

    .poster {
        max-width: 40%;
        float: left;
        margin-right: 8px;
        margin-bottom: 8px;
    }

    .html {
        p {
            margin: 0;
        }
    }

    section {

        header {
            margin-bottom: 8px;
        }
    }

    .geo-map {
        width: 100%;
    }

    address {
        margin-top: 8px;

        p {
            white-space: pre;
        }
    }

    .map-view {
        height: 50vh;
    }


    .map-container {
        position: relative;


        > * {
            position: absolute;
        }

        .loading-view {
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        border-top: solid 1px $border-color;
        border-bottom: solid 1px $border-color;
        margin-top: 16px;

        > * {
            flex-basis: 0;
            flex-grow: 1;
        }
    }
}
